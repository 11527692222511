<template>
  <section>
    <b-container class="py-md-4">
      <div :class="[style['register_wrapper'], 'my-md-5 my-4 p-3']">
        <b-row class="justify-content-lg-center">
          <b-col
            xl="10"
            lg="12"
          >
            <div
              class="register-wrapper-header text-center pt-5 mb-5"
            >
              <h1
                :class="[
                  style['title'],
                  'font-weight-bold mb-4',
                ]"
              >
                Error
              </h1>
              <div
                class="h5 d-flex align-items-center justify-content-center mb-4"
              >
                We can't process your payment right now, so please try again later. We're
                sorry for the inconvenience.
              </div>

              <div
                class="h5 d-flex align-items-center justify-content-center"
              >
                <p>
                  <b-button
                    ref="button"
                    variant="dark"
                    :class="[style['exclusive-content-btn'],'btn-pop text-uppercase font-weight-bold ml-5 w']"
                    squared
                    block
                    @click="() => $router.push('/')"
                  >
                    Back to News Feed
                  </b-button>
                </p>
              </div>
            </div>
          </b-col>
          <b-row />
        </b-row>
      </div>
    </b-container>
  </section>
</template>

<script>
import style from "../styles/registration.module.scss";

export default {
    name: "PaymentError",
    data() {
        return {
            style
        };
    }
};
</script>
